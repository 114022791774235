
import AllRoutes from "./routes/AllRoutes";



function App() {
 
  return (
    <AllRoutes />
  )
}

export default App;
