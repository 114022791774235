import Contact from "./Contact";
import "./style.scss";

export default function RightHome({ user }) {
  const color = "#65676b";
  return (
    <div className="right_home">
      <div style={{display:'flex' }}>
      <img src="../../../left/friends.png" alt=""  />
      <div className="heading">Following</div>
      </div>
      <div className="splitter1"></div>
      <div className="contacts_wrap">
        <div className="contacts_header">
          {/* <div className="contacts_header_left">Contacts</div> */}
          {/* <div className="contacts_header_right">
            <div className="contact_circle hover1">
              <NewRoom color={color} />
            </div>
            <div className="contact_circle hover1">
              <Search color={color} />
            </div>
            <div className="contact_circle hover1">
              <Dots color={color} />
            </div>
          </div> */}
        </div>
        <div className="contacts_list">
          {user?.following ?.map((friend, i)=>(
          <Contact friend={friend} key={i} />   
          ))}
        </div>
       
      </div>
    </div>
  );
}
